import React, { useCallback } from 'react';
import { UserModel } from '../../../mobx/models/user';
import {
  Check,
  Inputvalue2,
  Invite,
  Members,
  Modalblock,
  Modalhead,
  Name,
  Profile,
  Searchbar,
  Taskbutton
} from '../../../pages/project/details/setting/styles';
import Avatar from '../../avatar/Avatar';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Headline, Icon1, Input, InputDiv } from './style';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { ProjectModel } from '../../../mobx/models/project';
import { WorkspaceModel } from '../../../mobx/models/workspace';
import { useDispatch } from 'react-redux';
import { addInvitedMember, getCurrentProjectDetails, removeInvitedMember } from '../../../services/projectServices';
import { PROJECT_ANALYTICS } from '../../../global/analyticsConstants';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { captureException } from '@sentry/react';

interface InviteMemberModalProps {
  onClose: () => void;
  projectName: string;
  renderData: UserModel[];
  query: string;
  onChangeSearch: (e: { target: { value: string } }) => void;
  customersUsers?: UserModel[];
  isWorkspaceUser: boolean;
  onClickWorkspaceButton: (e: React.SyntheticEvent) => void;
  onClickClientButton: (e: React.SyntheticEvent) => void;
  currentProjectUsers?: UserModel[];
  onClickMemberBox?: (userIndex: number, userItem: UserModel) => void;
  onSubModel: () => void;
  setLoading?: (value: boolean) => void;
  currentProject?: ProjectModel;
  workspace?: WorkspaceModel;
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
  onClose,
  projectName,
  renderData,
  query,
  onChangeSearch,
  customersUsers,
  isWorkspaceUser,
  onClickWorkspaceButton,
  onClickClientButton,
  currentProjectUsers,
  currentProject,
  onSubModel,
  setLoading,
  workspace,
  onClickMemberBox
}) => {
  // Other variables
  const dispatch = useDispatch();

  // handle member box click
  const handleMemberClickAction = useCallback(
    async (userIndex: number | undefined, invitedUser: UserModel) => {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      try {
        setLoading?.(true);
        let result;
        let usersClone = JSON.parse(JSON.stringify(currentProject?.users));
        if (userIndex === -1) {
          const payload = {
            userId: userDetails?.id,
            newUserId: invitedUser?.id,
            companyId: workspace?.id,
            projectId: currentProject?.id
          };
          usersClone = [...(usersClone || []), invitedUser];
          currentProject?.addUser(invitedUser?.id);
          result = await dispatch(addInvitedMember(payload));
          trackAnalyticActivity(PROJECT_ANALYTICS.MEMBER_ADDED);
        } else {
          usersClone.splice(userIndex, 1);
          currentProject?.removeUser(invitedUser?.id);
          result = await dispatch(removeInvitedMember(userDetails?.id, currentProject?.id, invitedUser?.id));
          trackAnalyticActivity(PROJECT_ANALYTICS.MEMBER_REMOVED);
        }
        if (result) {
          await dispatch(getCurrentProjectDetails(workspace?.id, currentProject?.id));
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading?.(false);
      }
    },
    [setLoading, currentProject, workspace?.id, dispatch]
  );

  const renderMemberList = useCallback(
    (renderData: UserModel[]) => {
      return (
        <Modalblock>
          <Searchbar>
            <InputDiv>
              <Input placeholder='Search by name' className='search' value={query} onChange={onChangeSearch} />
              <SVGIcon name='search-icon' width='18' height='18' viewBox='0 0 16 16' className='icon' />
            </InputDiv>
            {(customersUsers?.length ?? 0) > 0 && (
              <div style={{ display: 'flex', gap: 8, paddingTop: 12 }}>
                <Taskbutton isActive={isWorkspaceUser} onClick={onClickWorkspaceButton}>
                  Workspace
                </Taskbutton>
                <Taskbutton isActive={!isWorkspaceUser} onClick={onClickClientButton}>
                  Client
                </Taskbutton>
              </div>
            )}
          </Searchbar>
          <Members>
            {renderData?.map((userItem) => {
              const userIndex = currentProjectUsers?.findIndex((user) => user?.id === userItem?.id) ?? -1;
              return (
                <Profile
                  key={userItem?.id}
                  onClick={() =>
                    onClickMemberBox
                      ? onClickMemberBox(userIndex, userItem)
                      : handleMemberClickAction(userIndex, userItem)
                  }>
                  <Name>
                    <Avatar
                      imgSrc={userItem?.profile_photo || ''}
                      name={userItem?.name ? userItem.name : 'U N'}
                      size={28}
                      isNotBorder={true}
                    />
                    <p>{userItem?.name}</p>
                  </Name>
                  <Check>
                    <Inputvalue2 type='checkbox' className='checkbox-round' checked={userIndex === -1 ? false : true} />
                  </Check>
                </Profile>
              );
            })}
          </Members>
          <Invite onClick={onSubModel}>Invite New Member</Invite>
        </Modalblock>
      );
    },
    [
      query,
      onChangeSearch,
      customersUsers?.length,
      isWorkspaceUser,
      onClickWorkspaceButton,
      onClickClientButton,
      onSubModel,
      currentProjectUsers,
      onClickMemberBox,
      handleMemberClickAction
    ]
  );

  return (
    <>
      <Modalhead>
        <Headline>Invite member to {projectName} project.</Headline>
        <Icon1 onClick={onClose}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='closeicon' />
        </Icon1>
      </Modalhead>
      {renderMemberList(renderData)}
    </>
  );
};

export default InviteMemberModal;
