import { css } from 'styled-components';

export const Typography = {
  heading_3xl: css`
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.54px;
  `,
  heading_2xl: css`
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.3px;
  `,
  heading_xl: css`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.2px;
  `,
  heading_xl_semibold: css`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  `,
  heading_lg: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
    @media (max-width: 449px) {
      font-size: 24px;
      line-height: 32px;
    }
  `,
  heading_md: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  heading_sm: css`
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_lg: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_lg_medium: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_lg_semibold: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_md: css`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_md_medium: css`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_md_semibold: css`
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    @media (max-width: 449px) {
      font-size: 15px;
      line-height: 22px;
    }
  `,
  body_sm: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    @media (max-width: 449px) {
      font-size: 13px;
      line-height: 20px;
    }
  `,
  body_sm_medium: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    @media (max-width: 449px) {
      font-size: 13px;
      line-height: 20px;
    }
  `,
  body_sm_semibold: css`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    @media (max-width: 449px) {
      font-size: 13px;
      line-height: 20px;
    }
  `,
  body_xs: css`
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
  `,
  body_xs_semibold: css`
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
  `,
  body_xl: css`
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  `,
  body_xl_medium: css`
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
  `,
  body_xl_semibold: css`
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
  `
};
