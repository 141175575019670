import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Buttons,
  Desc,
  HeaderInbox,
  InboxMSG,
  MSG,
  MSGdetail,
  MSGtime,
  Read,
  MSGtitle,
  MSGtext,
  MSGsubtext,
  MSGblock,
  Mainheader,
  IconView,
  Avtardot,
  Icons,
  Bottom,
  Bottombutton,
  BlankDiv,
  Leftside,
  Rightside,
  Div,
  Heading,
  Title,
  Menu,
  SvgDiv,
  Empty,
  Header,
  Calendardropdown,
  Bottomdiv,
  Buttonsdiv,
  Inputdiv,
  Inputs,
  Inputlabel,
  Inputvalue,
  Input,
  Content,
  Overlay,
  InboxPreviewAction,
  ActionIcon,
  ActionSection,
  DetailPreview,
  PreviewSection,
  NotificationCard,
  DesktopIcon,
  Description,
  DesktopImage,
  RightContent,
  DateSection,
  Arrowicon,
  MainItemDiv,
  ResponsiveDropdown,
  ResponsiveArchiveIcon,
  FilterIcon
  // FilterIcon
  // ResponsiveArchiveIcon,
  // ResponsiveDropdown
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotification,
  loadInboxData,
  updateNotification,
  clearAllNotifications
} from '../../services/inboxServices';
import moment from 'moment';
import { INBOX_FILTER_TYPE, NOTIFICATION_TYPE, ONESIGNLE_APP_ID } from '../../global/constants';
import Avatar from '../../component/avatar/Avatar';
import { useHistory } from 'react-router-dom';
import {
  convertToUTCISO,
  formatToDateString,
  getCurrentDomain,
  getUrlParam,
  isElectronApp,
  isEmpty
} from '../../helpers/common';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import Button from '../../component/button';
import { ROUTES } from '../../routes/urls';
import { initPushNotification } from '../../services/appService';
import RenderInboxHtml from '../../component/renderInbox';
import { captureException } from '../../services/logService';
import { CustomWindow } from '../../interfaces/OneSignalInterface';
import ModalCustom from '../../component/models/modal';
import Deletemodal from '../../component/models/deleteModel';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import Inboxloading from '../../component/loading/inboxLoading';
import EmptyState from '../../component/emptyState';
import inboxempty from '../../../src/assets/images/emptystates/inboxemptystate.svg';
import darkinbosempty from '../../../src/assets/images/emptystates/darkinboxempty.svg';
import { useMobileDevice } from '../../global/useMobile';
import CalendarComponent from '../../component/calendarPopUp/Calendarcomponent';
import TimePickerModal from '../../component/timePicker';
import dayjs from 'dayjs';
import { setErrorMessage, setSuccessMessage } from '../../actions/messageActions';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';
import TaskDetailPreview from '../../component/models/taskDetailPreviewModal';
import desktopappmac from '../../assets/images/desktopapp-mac.png';
import { Dropdown, DropdownDivider, DropdownItem } from '../../component/Dropdown';
import { BottomNavigation } from '../../component/bottomNavigation';
import { rootStore } from '../../mobx/rootStore';
import { observer } from 'mobx-react-lite';
import { NotificationModel } from '../../mobx/models/notification';

declare const window: CustomWindow;

const Inbox: React.FC = () => {
  //Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const dropdownRefCalendar = useRef<HTMLDivElement | null>(null);
  const mobileDropdownTriggerRefs = useRef<React.RefObject<HTMLDivElement>[]>([]); // Ref for mobile dropdown triggers

  //States
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [overDueCardDismissed, setOverDueCardDismissed] = useState(false);
  const [notificationCardDismiss, setNotificationCardDismiss] = useState(false);
  const [desktopCardDismiss, setDesktopCardDismiss] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState('');
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');
  const [alignment, setAlignment] = useState<'left' | 'center' | 'right'>('left');
  const [dropdownTopOffset, setDropdownTopOffset] = useState<number>(0);
  const [buttonHeight, setButtonHeight] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState('00:00');
  const [selectedDate, setSelectedDate] = useState('');
  const [clientWindowHeight, setClientWindowHeight] = useState(0);
  const [isTaskDetailOpen, setIsTaskDetailOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<NotificationModel | null>();
  const [selectedTaskIndex, setSelectedTaskIndex] = useState<number | null>();
  const [buttonDisable, setButtonDisable] = useState({
    forward: false,
    backward: false
  });
  const [longPressTimerId, setLongPressTimerId] = useState<NodeJS.Timeout | null>(null);
  const [touchStartPos, setTouchStartPos] = useState<{ x: number; y: number } | null>(null);
  const [hasMoved, setHasMoved] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { task: taskSelector, settings: settingsSelector } = stateSelector || {};
  const { overDuetasks, taskDetails } = taskSelector;
  const { themeMode } = settingsSelector;

  //Mobx store variables
  const { notificationStore, workspaceStore } = rootStore;
  const { notifications, filteredNotifications } = notificationStore;
  const { currentWorkspace } = workspaceStore;
  const workspace_id = currentWorkspace?.id || '';

  //Other variable
  const mobile = useMobileDevice();
  const getImageSource = () => (themeMode?.theme === 'dark' ? darkinbosempty : inboxempty);
  const dispatch = useDispatch();
  const history = useHistory();
  const filterType = Number(getUrlParam(history.location.search, 'type'));
  let isScrollPage: boolean;
  const MOVE_THRESHOLD = 10; // Pixels to detect movement vs. press

  if (clientWindowHeight > 10) {
    isScrollPage = true;
  } else {
    isScrollPage = false;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const snoozeMenuItems = [
    {
      key: 'today',
      iconName: 'today-snooze-icon',
      label: 'Today',
      rightSideLabel: moment().format('ddd')
    },
    {
      key: 'tomorrow',
      iconName: 'tomorrow-icon',
      label: 'Tomorrow',
      rightSideLabel: moment().add(1, 'days').format('ddd')
    },
    {
      key: 'this-Week',
      iconName: 'thisweek-snooze-icon',
      label: 'This Week'
    },
    {
      key: 'next-Week',
      iconName: 'next-week-icon',
      label: 'Next Week'
    }
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const overDueTime = UserPreferenceSingleton.getInstance().getOverDueResheduleTime() || {};
  const notificationResheduleTime = UserPreferenceSingleton.getInstance().getNotificationReshedule() || {};
  const desktopNotificationResheduleTime =
    UserPreferenceSingleton.getInstance().getDesktopNotificationReshedule() || '';
  const overDueResheduletime = overDueTime[workspace_id] || '';
  const isShowNotificationCard =
    (isEmpty(notificationResheduleTime) ||
      (!isEmpty(notificationResheduleTime) && new Date(notificationResheduleTime).getTime() <= new Date().getTime())) &&
    !notificationCardDismiss &&
    permissionStatus !== 'denied' &&
    permissionStatus !== 'granted' &&
    getCurrentDomain() === 'dash.teamcamp.app';
  const isShowDesktopNotification =
    !isElectronApp() &&
    !mobile &&
    !desktopCardDismiss &&
    (isEmpty(desktopNotificationResheduleTime) ||
      (!isEmpty(desktopNotificationResheduleTime) &&
        new Date(desktopNotificationResheduleTime).getTime() <= new Date().getTime()));
  const isShowOverdueCard =
    (isEmpty(overDueResheduletime) ||
      (!isEmpty(overDueResheduletime) && new Date(overDueResheduletime).getTime() <= new Date().getTime())) &&
    !overDueCardDismissed &&
    overDuetasks > 0;

  // condition for calendar dropdown position set
  useEffect(() => {
    if (isCalendarOpen && dropdownRefCalendar?.current && buttonRef?.current) {
      // Check if openDropdownId is not null
      if (openDropdownId) {
        const index = filteredNotifications?.findIndex((item) => item?.id === openDropdownId);

        // Ensure the index is valid and that the reference exists
        if (index !== -1 && buttonRef?.current[index] && buttonRef?.current[index].current) {
          // Get rect for the active button
          const buttonRect = buttonRef?.current[index]?.current?.getBoundingClientRect();
          const buttonHeight = buttonRef?.current[index]?.current?.offsetHeight;
          const dropdownHeight = dropdownRefCalendar?.current?.offsetHeight;
          setButtonHeight(buttonHeight);

          // Calculate available space
          const spaceBelow = window.innerHeight - buttonRect.bottom;
          const spaceAbove = buttonRect.top;
          const spaceLeft = buttonRect.left;
          const spaceRight = window.innerWidth - buttonRect.right;
          // calculate dropdown width for alignment of dropdown
          const dropdownWidth = dropdownRefCalendar.current.offsetWidth;

          // check space below and above and then set position of the dropdown
          if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
            setPosition('top');
            setDropdownTopOffset(buttonRect.top - dropdownHeight);
          } else {
            setPosition('bottom');
            setDropdownTopOffset(buttonRect.top);
          }
          // check left & right space and then alignt the dropdown
          if (spaceLeft < dropdownWidth && spaceRight < dropdownWidth) {
            setAlignment('center');
          } else if (spaceRight < dropdownWidth) {
            setAlignment('right');
          } else {
            setAlignment('left');
          }
        }
      }
    }
  }, [alignment, dropdownTopOffset, isCalendarOpen, filteredNotifications, openDropdownId, position]);

  //light and dark mode github icon
  const getSvgIcon = useCallback(() => {
    return themeMode?.theme === 'dark' ? 'github-inbox-dark-icon' : 'github-inbox-light-icon';
  }, [themeMode?.theme]);

  // handle time change
  const handleTimeChange = useCallback((value: string) => {
    setSelectedTime(value);
  }, []);

  // open delete model
  const OpenDeleteModel = useCallback(() => {
    setDeleteModelOpen(true);
  }, []);

  // close delete model
  const closeDeleteModel = useCallback(() => {
    setDeleteModelOpen(false);
  }, []);

  // load initial data
  const loadData = useCallback(async () => {
    if (isEmpty(workspace_id)) return;
    try {
      setLoading(true);
      notificationStore.setFilterType(filterType);
      await dispatch(loadInboxData());
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, filterType, notificationStore, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  //handle inbox tab change
  const inboxTabChange = useCallback(
    (filterType: number) => {
      notificationStore.setFilterType(filterType);
      history.push(`${history.location.pathname}?type=${filterType}`);
    },
    [notificationStore, history]
  );

  // manage button dynamically
  const updateButtonState = useCallback((list: NotificationModel[], item: NotificationModel, taskIndex: number) => {
    const isValidTask = () =>
      [
        NOTIFICATION_TYPE.COMMENT,
        NOTIFICATION_TYPE.TASK,
        NOTIFICATION_TYPE.INVITE,
        NOTIFICATION_TYPE.PROJECT_INVITE
      ].includes(item?.type) &&
      item?.projectId &&
      item?.taskId;
    const hasForwardTask = list?.slice(taskIndex + 1).some(isValidTask);
    const hasBackwardTask = list?.slice(0, taskIndex).some(isValidTask);
    setButtonDisable({ forward: !hasForwardTask, backward: !hasBackwardTask });
  }, []);

  // redirect to task detail page
  const onClickInboxItem = useCallback(
    async (event: React.MouseEvent<HTMLDivElement>, notification: NotificationModel, index: number) => {
      if (!notification) return;
      const handleUpdateNotification = async () => {
        await dispatch(updateNotification(notification.id));
      };

      const handleMarkTaskAsRead = () => {
        notifications[index].markAsRead();
      };

      const trackViewAnalytics = () => {
        trackAnalyticActivity(TASK_ANALYTICS.VIEW, { source: 'inbox', title: notification?.description || '' });
      };

      const handleTaskDetailRedirection = () => {
        setIsTaskDetailOpen(true);
        setSelectedTask(notification);
        setSelectedTaskIndex(index);
        updateButtonState(filteredNotifications, notification, index);
      };

      const navigateToTaskDetails = () => {
        history.push(`/projects/details/${notification.projectId}/tasks?task=${notification.taskId}`);
      };

      const navigateToProjectDetails = () => {
        history.push(`/projects/details/${notification.projectId}/tasks`);
      };

      const navigateToMessages = () => {
        const path = mobile
          ? `/messages/details?chatGroup=${notification.projectId}`
          : `/messages?chatGroup=${notification.projectId}`;
        history.push(path);
      };

      if (
        [
          NOTIFICATION_TYPE.COMMENT,
          NOTIFICATION_TYPE.TASK,
          NOTIFICATION_TYPE.INVITE,
          NOTIFICATION_TYPE.PROJECT_INVITE
        ].includes(notification.type)
      ) {
        await handleUpdateNotification();

        if (notification.projectId && notification.taskId) {
          if (!notification.isRead) {
            handleMarkTaskAsRead();
          }
          trackViewAnalytics();
          if (event.metaKey || event.ctrlKey) {
            handleTaskDetailRedirection();
          } else {
            navigateToTaskDetails();
          }
        } else if (notification.projectId && isEmpty(notification.companyId)) {
          if (notification.type === NOTIFICATION_TYPE.COMMENT) {
            navigateToMessages();
          } else {
            navigateToProjectDetails();
          }
        }
      }
    },
    [dispatch, history, mobile, filteredNotifications, notifications, updateButtonState]
  );

  // manage read icon click
  const onClickReadIcon = useCallback(
    async (e: React.SyntheticEvent | undefined, notification: { id: string; isRead: boolean }, index: number) => {
      e?.stopPropagation();
      if (notification.isRead) return;
      notifications[index].markAsRead();
      await dispatch(updateNotification(notification?.id));
      setIsTaskDetailOpen(false);
    },
    [dispatch, notifications]
  );

  // handle scroll
  const handleScroll = useCallback(() => {
    setClientWindowHeight(window.scrollY);
  }, []);

  // clear all notification
  const clearAllMessages = useCallback(async () => {
    try {
      setButtonLoading(true);
      const response = await dispatch(clearAllNotifications());
      if (response) {
        closeDeleteModel();
        rootStore.notificationStore.setNotifications([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setButtonLoading(false);
    }
  }, [closeDeleteModel, dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    initPushNotification();
  }, []);

  // get notification permission
  const getNotificationPermission = useCallback(async () => {
    window.OneSignal.push(function () {
      if (window.Notification?.permission) setPermissionStatus(window.Notification.permission);
    });
  }, []);

  // manage notification permission change
  const handlePermissionChange = useCallback((newPermission: { to: string }) => {
    if (newPermission?.to === 'default') UserPreferenceSingleton.getInstance().setOneSignalInit(false);
    setPermissionStatus(newPermission?.to);
  }, []);

  useEffect(() => {
    if (window.OneSignal) {
      window.OneSignal.push(() => {
        window.OneSignal.on('notificationPermissionChange', handlePermissionChange);
      });
      getNotificationPermission();
      return () => {
        window.OneSignal.push(() => {
          window.OneSignal.off('notificationPermissionChange', handlePermissionChange);
        });
      };
    } else {
      setPermissionStatus('denied');
    }
  }, [getNotificationPermission, handlePermissionChange]);

  // render icon for notification
  const renderProfileIcons = useCallback((type: number, title: string) => {
    const assignRegex = /Assigned you a task/i;
    const reopenRegex = /reopened a task/i;
    const completeRegex = /completed a task/i;
    const replyMessageRegex = /reply you on/i;
    const assignIcon = assignRegex?.test(title);
    const reopenIcon = reopenRegex?.test(title);
    const completeIcon = completeRegex?.test(title);
    const replyMessageIcon = replyMessageRegex?.test(title);

    switch (type) {
      case NOTIFICATION_TYPE.TASK:
        if (assignIcon) {
          return <SVGIcon name='assign-task-icon' width='16' height='16' viewBox='0 0 16 16' className='taskicon' />;
        } else if (reopenIcon) {
          return (
            <SVGIcon
              name='reopen-task-icon'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              className='project-invited-icon'
            />
          );
        } else if (completeIcon) {
          return <SVGIcon name='complate-task-icon' width='16' height='16' viewBox='0 0 16 16' className='taskicon' />;
        }
        break;
      case NOTIFICATION_TYPE.COMMENT:
        if (replyMessageIcon) {
          return <SVGIcon name='reply-message-icon' width='16' height='16' viewBox='0 0 16 16' className='taskicon' />;
        } else {
          return <SVGIcon name='mention-task-icon' width='16' height='16' viewBox='0 0 16 16' className='taskicon' />;
        }
      case NOTIFICATION_TYPE.PROJECT_INVITE:
      case NOTIFICATION_TYPE.INVITE:
        return (
          <SVGIcon
            name='project-invited-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='project-invited-icon'
          />
        );

      default:
        return <></>;
    }
  }, []);

  // for dismiss overdue card
  const onClickDismiss = useCallback(() => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    tomorrow.setHours(9, 0, 0, 0);
    const overDueTimeObject = {
      ...overDueTime,
      [workspace_id]: tomorrow.toISOString()
    };
    UserPreferenceSingleton.getInstance().setOverDueResheduleTime(overDueTimeObject);
    setOverDueCardDismissed(true);
  }, [overDueTime, workspace_id]);

  // for enable notification overdue card
  const onEnableNotification = useCallback(async () => {
    try {
      window.OneSignal.push(function () {
        window.OneSignal.init({
          appId: ONESIGNLE_APP_ID
        });
      });
      window.OneSignal.push(function () {
        window.OneSignal.push(function () {
          window.OneSignal.push(['registerForPushNotifications']);
        });
      });
    } catch (e) {
      captureException(e);
    }
  }, []);

  // for disable (no enable) notification
  const onClickNoEnableNotification = useCallback(() => {
    const rescheduleDate = moment().add(1, 'weeks').toISOString();
    UserPreferenceSingleton.getInstance().setNotificationReshedule(rescheduleDate);
    setNotificationCardDismiss(true);
  }, []);

  // On click dismiss desktop notification
  const onDismissDesktopNotification = useCallback(() => {
    const rescheduleDate = moment().add(1, 'weeks').toISOString();
    UserPreferenceSingleton.getInstance().setDesktopNotificationReshedule(rescheduleDate);
    setDesktopCardDismiss(true);
  }, []);

  // render inbox card
  const renderCard = useCallback(
    ({
      iconName,
      title,
      text,
      buttonTitle,
      buttonAction,
      dismissAction,
      className
    }: {
      iconName: string;
      title: string;
      text: string;
      buttonTitle: string;
      buttonAction: () => void;
      dismissAction: () => void;
      className?: string;
    }) => {
      return (
        <MSG>
          <MSGdetail className={className}>
            <Avtardot>
              <IconView className='pointer'>
                <BlankDiv></BlankDiv>
              </IconView>
              <SVGIcon name={iconName} width={`28px`} height={`28px`} viewBox={`0 0 28 28`} className='svg-icon' />
            </Avtardot>
            <Read>
              <MSGtitle className={className}>{title}</MSGtitle>
              <MSGtext className={className}>{text}</MSGtext>
              <Bottombutton>
                <Button
                  title={buttonTitle}
                  secondary={true}
                  onClick={buttonAction}
                  smallbutton={true}
                  hasNotBoxshadow
                />
                <p onClick={dismissAction}>Dismiss</p>
              </Bottombutton>
            </Read>
          </MSGdetail>
        </MSG>
      );
    },
    []
  );

  // Handle inbox card button click
  const handleClick = useCallback(
    async (key: string | number, notificationId: string) => {
      let payload;
      if (key === 'today') {
        payload = {
          snoozeDate: moment().set({ hour: 15, minute: 0, second: 0 }).toISOString()
        };
      } else if (key === 'this-Week') {
        payload = {
          snoozeDate: moment().endOf('week').set({ hour: 17, minute: 0, second: 0 }).toISOString()
        };
      } else if (key === 'tomorrow') {
        payload = {
          snoozeDate: moment().add(1, 'days').set({ hour: 9, minute: 0, second: 0 }).toISOString()
        };
      } else if (key === 'next-Week') {
        payload = {
          snoozeDate: moment()
            .clone()
            .day(8) // Set to next Monday
            .set({ hour: 9, minute: 0, second: 0 })
            .toISOString()
        };
      } else if (key === 'date-time') {
        setOpenDropdownId(notificationId);
        setIsCalendarOpen(true);
      }
      if (key !== 'date-time') {
        setOpenDropdownId(null);
      }
      let result;
      if (payload) {
        const currentTime = new Date().toISOString();

        if (payload?.snoozeDate < currentTime) {
          dispatch(setErrorMessage('Please choose a date and time in the future.'));
          return;
        }
        result = await dispatch(updateNotification(notificationId, payload));
      }
      if (result) {
        notificationStore.removeNotifications([notificationId]);
      }
    },
    [dispatch, notificationStore]
  );

  // handle click of snooze icon
  const onClickSnooze = useCallback(async () => {
    if (isEmpty(selectedDate)) {
      dispatch(setErrorMessage('Please select a date.'));
      return;
    }
    if (!openDropdownId) return;
    const finalCombinedDate = convertToUTCISO(selectedDate, selectedTime);

    // Check if the final date is valid
    if (!finalCombinedDate || isNaN(new Date(finalCombinedDate).getTime())) {
      dispatch(setErrorMessage('Invalid final date.'));
      return;
    }

    const currentTime = new Date().toISOString();

    if (finalCombinedDate < currentTime) {
      dispatch(setErrorMessage('Please choose a date and time in the future.'));
      return;
    }

    const result = await dispatch(updateNotification(openDropdownId, { snoozeDate: finalCombinedDate }));
    if (result) {
      setIsCalendarOpen(false);
      setOpenDropdownId(null);
      dispatch(setSuccessMessage('Notification snoozed successfully.'));
      notificationStore.removeNotifications([openDropdownId]);
      setSelectedDate('');
      setSelectedTime('00:00');
      setIsTaskDetailOpen(false);
    }
  }, [dispatch, notificationStore, openDropdownId, selectedDate, selectedTime]);

  // handle dropdown close on outside click
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownRefCalendar.current && !dropdownRefCalendar.current.contains(event.target as Node)) {
      setIsCalendarOpen(false); // Close dropdown on outside click
      setOpenDropdownId(null);
    }
  }, []);

  useEffect(() => {
    if (isCalendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isCalendarOpen]);

  //  handle cancel snooze
  const onCancelSnooze = useCallback(() => {
    setIsCalendarOpen(false);
    setSelectedDate('');
    setSelectedTime('00:00');
    setOpenDropdownId(null);
  }, []);

  // handle close task detail
  const handleCloseTaskDetail = () => {
    setIsTaskDetailOpen(false);
    setSelectedTask(null);
    setSelectedTaskIndex(null);
  };

  // handle next notification for go to next notification
  const handleNextNotification = useCallback(
    (list?: NotificationModel[]) => {
      // Find the next valid task after the current task index
      if (!selectedTaskIndex && selectedTaskIndex !== 0) return;
      const notificationList = list || filteredNotifications || [];
      const nextTask = filteredNotifications
        ?.slice(selectedTaskIndex + 1)
        .find(
          (task) =>
            (task?.type === NOTIFICATION_TYPE.COMMENT ||
              task?.type === NOTIFICATION_TYPE.TASK ||
              task?.type === NOTIFICATION_TYPE.INVITE ||
              task?.type === NOTIFICATION_TYPE.PROJECT_INVITE) &&
            task?.projectId &&
            task?.taskId
        );
      if (nextTask) {
        const nextTaskIndex = notificationList?.findIndex((task) => task?.id === nextTask?.id);
        setSelectedTask(nextTask);
        setSelectedTaskIndex(nextTaskIndex);
        updateButtonState(notificationList, nextTask, nextTaskIndex);
      }
    },
    [filteredNotifications, selectedTaskIndex, updateButtonState]
  );

  // handle previous notification for go to previous notification
  const handlePreviousNotification = useCallback(
    (list?: NotificationModel[]) => {
      // Find the previous valid task before the current task index
      if (!selectedTaskIndex && selectedTaskIndex !== 0) return;
      const notificationList = list || filteredNotifications || [];
      const previousTask = [...filteredNotifications]
        ?.slice(0, selectedTaskIndex)
        .reverse()
        .find(
          (task) =>
            (task?.type === NOTIFICATION_TYPE.COMMENT ||
              task?.type === NOTIFICATION_TYPE.TASK ||
              task?.type === NOTIFICATION_TYPE.INVITE ||
              task?.type === NOTIFICATION_TYPE.PROJECT_INVITE) &&
            task?.projectId &&
            task?.taskId
        );
      if (previousTask) {
        const previousTaskIndex = notificationList?.findIndex((task) => task?.id === previousTask?.id);
        setSelectedTask(previousTask);
        setSelectedTaskIndex(previousTaskIndex);
        updateButtonState(notificationList, previousTask, previousTaskIndex);
      }
    },
    [filteredNotifications, selectedTaskIndex, updateButtonState]
  );

  // handle arrow click for forward and backward
  const handleArrowClick = useCallback(
    (type: string) => {
      if (
        (selectedTaskIndex || selectedTaskIndex === 0) &&
        selectedTaskIndex !== filteredNotifications?.length - 1 &&
        type === 'Forward'
      ) {
        handleNextNotification();
      } else if (selectedTaskIndex && selectedTaskIndex !== 0 && type === 'Backward') {
        handlePreviousNotification();
      }
    },
    [handleNextNotification, handlePreviousNotification, filteredNotifications, selectedTaskIndex]
  );

  // handle delete icon click
  const onClickDeleteIcon = useCallback(
    async (e: React.SyntheticEvent | undefined, notification: { id: string; isRead: boolean }) => {
      e?.stopPropagation();
      let updatedList = [...filteredNotifications];
      const indexOfNotificationToRemove = updatedList.findIndex((item) => item.id === notification.id);
      if (indexOfNotificationToRemove !== -1) {
        updatedList.splice(indexOfNotificationToRemove, 1);
        notificationStore.removeNotifications([notification.id]);
      }
      if (buttonDisable.forward && buttonDisable.backward) {
        setIsTaskDetailOpen(false);
      } else if (buttonDisable.forward) {
        handlePreviousNotification(updatedList);
      } else {
        handleNextNotification(updatedList);
      }
      await dispatch(deleteNotification(notification?.id));
    },
    [
      filteredNotifications,
      buttonDisable.forward,
      buttonDisable.backward,
      dispatch,
      notificationStore,
      handlePreviousNotification,
      handleNextNotification
    ]
  );

  // Render inbox button
  const renderInboxButton = useCallback(
    (item: NotificationModel, index: number, isTaskDetailPreview: boolean) => {
      return (
        <Icons className='inbox-icons' isTaskDetailPreview={isTaskDetailPreview}>
          <SvgDiv onClick={(e) => onClickReadIcon(e, item, index)} isTaskDetailPreview={isTaskDetailPreview}>
            <SVGIcon name='true-icon' width='16' height='16' viewBox='0 0 16 16' className='icons' />
          </SvgDiv>

          {/* Snooze Dropdown */}
          <Dropdown
            isRemoveMaxHeight={true}
            isMinWidth={218}
            content={
              <SvgDiv isTaskDetailPreview={isTaskDetailPreview} onClick={() => setOpenDropdownId(item?.id)}>
                <SVGIcon name='snooze-icon' width='16' height='16' viewBox='0 0 16 16' className='icon' />
              </SvgDiv>
            }
            trigger='click'
            onOutsideClick={() => {
              setIsCalendarOpen(false);
              setOpenDropdownId(null);
            }}>
            {!isCalendarOpen &&
              snoozeMenuItems.map((dropdownItem) => (
                <DropdownItem
                  key={dropdownItem.key}
                  label={dropdownItem.label}
                  iconName={dropdownItem.iconName}
                  iconSize={16}
                  iconViewBox='0 0 24 24'
                  isSuffix={dropdownItem.rightSideLabel}
                  onClick={() => handleClick(dropdownItem.key, item.id)}
                />
              ))}
            {!isCalendarOpen && <DropdownDivider />}
            {/* Date & Time Sub-dropdown */}

            {!isCalendarOpen && (
              <MainItemDiv>
                <DropdownItem
                  key='date-time'
                  label='Date & Time'
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsCalendarOpen((prev) => !prev);
                    setOpenDropdownId(item.id);
                  }}
                />
              </MainItemDiv>
            )}

            {isCalendarOpen && (
              <Calendardropdown
                buttonHeight={buttonHeight}
                direction={position}
                ref={dropdownRefCalendar}
                alignment={alignment}
                dropdownTopOffset={dropdownTopOffset}
                className='.reference-item'>
                <Header>
                  <Arrowicon
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCalendarOpen(false);
                    }}>
                    <SVGIcon
                      name='left-arrow-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='arrow-icon'
                    />
                  </Arrowicon>
                  <p>Snooze</p>
                </Header>
                <div style={{ placeItems: 'center', padding: '0 8px 10px' }}>
                  <CalendarComponent
                    isInbox={true}
                    setCalanderDate={(date) => setSelectedDate(formatToDateString(date.toISOString()))}
                  />
                </div>
                <Bottomdiv>
                  <Inputdiv>
                    <Inputs>
                      <Inputlabel>Date</Inputlabel>
                      <Inputvalue type='text' placeholder='DD/MM/YY' value={selectedDate} disabled />
                    </Inputs>
                    <Inputs>
                      <Inputlabel>Time</Inputlabel>
                      <Input onClick={(e) => e.stopPropagation()}>
                        <TimePickerModal
                          value={dayjs(selectedTime, 'HH:mm')}
                          onSelect={(value) => handleTimeChange(value.format('HH:mm'))}
                          style={{ width: '100%', height: '36px' }}
                        />
                      </Input>
                    </Inputs>
                  </Inputdiv>
                  <Buttonsdiv>
                    <Button
                      title='Cancel'
                      secondary={true}
                      modelbtn={true}
                      hasNotBoxshadow
                      width='93'
                      className='button'
                      onClick={onCancelSnooze}
                    />
                    <Button title='Snooze' modelbtn={true} width='93' className='button' onClick={onClickSnooze} />
                  </Buttonsdiv>
                </Bottomdiv>
              </Calendardropdown>
            )}
          </Dropdown>

          {/* Delete Icon */}
          <SvgDiv onClick={(e) => onClickDeleteIcon(e, item)} isTaskDetailPreview={isTaskDetailPreview}>
            <SVGIcon name='bin-icon' width='16' height='16' viewBox='0 0 16 16' className='icons' />
          </SvgDiv>
        </Icons>
      );
    },
    [
      snoozeMenuItems,
      isCalendarOpen,
      buttonHeight,
      position,
      alignment,
      dropdownTopOffset,
      selectedDate,
      selectedTime,
      onCancelSnooze,
      onClickSnooze,
      onClickReadIcon,
      handleClick,
      handleTimeChange,
      onClickDeleteIcon
    ]
  );

  // Function to clear the long press timer and reset movement states
  const clearLongPress = useCallback(() => {
    if (longPressTimerId) {
      clearTimeout(longPressTimerId);
      setLongPressTimerId(null);
    }
    setTouchStartPos(null);
    setHasMoved(false);
  }, [longPressTimerId]);

  const handlePressStart = useCallback(
    (index: number, clientX: number, clientY: number) => {
      if (!mobile) return;
      // Clear any existing timer and reset movement state
      clearLongPress();
      setHasMoved(false);
      setTouchStartPos({ x: clientX, y: clientY });

      // Start a new timer
      const timerId = setTimeout(() => {
        // Only trigger if the touch/mouse hasn't moved
        if (!hasMoved) {
          mobileDropdownTriggerRefs.current[index]?.current?.click();
        }
        // Clear timer ID after execution or cancellation
        setLongPressTimerId(null);
        setTouchStartPos(null); // Reset position after timer fires or is cancelled by move
      }, 500); // 500ms duration for long press

      setLongPressTimerId(timerId);
    },
    [clearLongPress, hasMoved, mobile] // Include hasMoved in dependency
  );

  const handleMove = useCallback(
    (clientX: number, clientY: number) => {
      // Only check for movement if a press has started and a timer is potentially running
      if (!touchStartPos || !longPressTimerId) {
        return;
      }

      const deltaX = Math.abs(clientX - touchStartPos.x);
      const deltaY = Math.abs(clientY - touchStartPos.y);

      // If movement exceeds threshold, mark as moved and clear the timer
      if (deltaX > MOVE_THRESHOLD || deltaY > MOVE_THRESHOLD) {
        setHasMoved(true);
        clearLongPress(); // This cancels the timer
      }
    },
    [touchStartPos, longPressTimerId, clearLongPress]
  );

  // List of all notifications
  const renderNotificationList = useMemo(() => {
    if (!filteredNotifications) return null;
    return filteredNotifications.map((item, index: number) => {
      if (!buttonRef.current[index]) {
        buttonRef.current[index] = React.createRef<HTMLDivElement>();
      }
      if (!mobileDropdownTriggerRefs.current[index]) {
        mobileDropdownTriggerRefs.current[index] = React.createRef<HTMLDivElement>();
      }
      return (
        <MSG
          key={item?.id}
          isRead={item?.isRead === true}
          onClick={(e) => !hasMoved && onClickInboxItem(e, item, index)} // Prevent click if moved
          isIconActive={item?.id === openDropdownId}
          // Mouse Events
          onMouseDown={(e) => handlePressStart(index, e.clientX, e.clientY)}
          onMouseUp={clearLongPress}
          onMouseMove={(e) => handleMove(e.clientX, e.clientY)}
          onMouseLeave={clearLongPress}
          // Touch Events
          onTouchStart={(e) => handlePressStart(index, e.touches[0].clientX, e.touches[0].clientY)}
          onTouchEnd={clearLongPress}
          onTouchMove={(e) => handleMove(e.touches[0].clientX, e.touches[0].clientY)}
          onTouchCancel={clearLongPress}>
          <MSGdetail>
            <Avtardot>
              <IconView className='pointer'>
                <SVGIcon
                  name='active-point-icon'
                  width='5'
                  height='6'
                  viewBox='0 0 5 6'
                  fill='none'
                  className='dot-icon'
                />
              </IconView>
              {/* github icon */}
              {item?.senderId === 'github' ? (
                <SVGIcon name={getSvgIcon()} width='28' height='28' viewBox='0 0 28 28' className='github-icon' />
              ) : (
                <Avatar
                  imgSrc={item?.user?.profile_photo || ''}
                  name={item?.user?.name || ''}
                  size={26}
                  isThinBorder={true}
                  classname='avatar'
                />
              )}
              <IconView>{renderProfileIcons(item?.type, item?.title)}</IconView>
            </Avtardot>
            <MSGblock>
              <Read>
                <MSGtitle>
                  {item?.title}
                  {/* for mobile view */}
                  <span className='dot-icon'>・</span>
                  <DateSection>{moment(item?.date).fromNow()}</DateSection>
                </MSGtitle>
                {item?.type === NOTIFICATION_TYPE.TASK && <MSGtext>{item?.description}</MSGtext>}
              </Read>
              {item?.type === NOTIFICATION_TYPE.COMMENT && (
                <Desc
                  isImage={
                    item?.description?.includes('figma-preview-image') ||
                    item?.description?.includes('screen-recording-custom-main-div') ||
                    item?.description?.includes('figma-link-div') ||
                    item?.description?.includes('prnt-preview-main-div') ||
                    item?.description?.includes('image') ||
                    item?.description?.includes('youtube-preview-main-div')
                  }>
                  <RenderInboxHtml htmlstring={item?.description} />
                </Desc>
              )}
            </MSGblock>
          </MSGdetail>
          {/* for Desktop view */}
          <MSGtime>
            <MSGsubtext>{moment(item?.createdTime).fromNow()}</MSGsubtext>
            <Bottom>{renderInboxButton(item, index, false)}</Bottom>
          </MSGtime>
          {/* Mobile Dropdown - Triggered by long press */}
          {mobile && (
            <ResponsiveDropdown>
              {/* Use a visible trigger and pass the ref */}
              <Dropdown
                content={<div ref={mobileDropdownTriggerRefs.current[index]} style={{ display: 'none' }}></div>}
                trigger='click'
                isRemoveMaxHeight={true}>
                {/* Define the actual dropdown items here */}
                <DropdownItem
                  label='Mark as Read'
                  iconName='true-icon'
                  onClick={(e) => {
                    onClickReadIcon(e, item, index);
                    mobileDropdownTriggerRefs.current[index]?.current?.click();
                  }}
                  iconTone='stroke'
                />
                <Dropdown
                  content={<DropdownItem label='Snooze' iconName='snooze-icon' isSuffix={true} />}
                  trigger='click' // Nested trigger
                  onOutsideClick={() => {
                    setIsCalendarOpen(false);
                  }}
                  isRemoveMaxHeight={true}
                  isMinWidth={218} // Match desktop snooze width
                >
                  {!isCalendarOpen &&
                    snoozeMenuItems.map((dropdownItem) => (
                      <DropdownItem
                        key={dropdownItem.key}
                        label={dropdownItem.label}
                        iconName={dropdownItem.iconName}
                        iconSize={16}
                        iconViewBox='0 0 24 24'
                        isSuffix={dropdownItem.rightSideLabel}
                        onClick={(e) => {
                          e?.stopPropagation(); // Prevent outer dropdown close
                          handleClick(dropdownItem.key, item.id);
                        }}
                      />
                    ))}
                  {!isCalendarOpen && <DropdownDivider />}
                  {!isCalendarOpen && (
                    <MainItemDiv>
                      <DropdownItem
                        key='date-time'
                        label='Date & Time'
                        onClick={(e) => {
                          e?.stopPropagation(); // Prevent outer dropdown close
                          setIsCalendarOpen((prev) => !prev);
                          setOpenDropdownId(item.id); // Ensure calendar knows which item it's for
                        }}
                        isSuffix={true}
                      />
                    </MainItemDiv>
                  )}
                  {/* Calendar - Render conditionally based on isCalendarOpen and correct openDropdownId */}
                  {isCalendarOpen && openDropdownId === item.id && (
                    <Calendardropdown
                      ref={dropdownRefCalendar} // Still use the same ref
                      onClick={(e) => e.stopPropagation()} // Prevent outer dropdown close
                    >
                      {/* ... Calendar Header, Component, Inputs, Buttons ... */}
                      <Header>
                        <Arrowicon
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsCalendarOpen(false); // Close only calendar
                          }}>
                          <SVGIcon
                            name='left-arrow-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 16 16'
                            fill='none'
                            className='arrow-icon'
                          />
                        </Arrowicon>
                        <p>Snooze</p>
                      </Header>
                      <div style={{ placeItems: 'center', padding: '0 8px 10px' }}>
                        <CalendarComponent
                          isInbox={true}
                          setCalanderDate={(date) => setSelectedDate(formatToDateString(date.toISOString()))}
                        />
                      </div>
                      <Bottomdiv>
                        <Inputdiv>
                          <Inputs>
                            <Inputlabel>Date</Inputlabel>
                            <Inputvalue type='text' placeholder='DD/MM/YY' value={selectedDate} disabled />
                          </Inputs>
                          <Inputs>
                            <Inputlabel>Time</Inputlabel>
                            <Input onClick={(e) => e.stopPropagation()}>
                              <TimePickerModal
                                value={dayjs(selectedTime, 'HH:mm')}
                                onSelect={(value) => handleTimeChange(value.format('HH:mm'))}
                                style={{ width: '100%', height: '36px' }}
                              />
                            </Input>
                          </Inputs>
                        </Inputdiv>
                        <Buttonsdiv>
                          <Button
                            title='Cancel'
                            secondary={true}
                            modelbtn={true}
                            hasNotBoxshadow
                            width='93'
                            className='button'
                            onClick={(e: SyntheticEvent) => {
                              e.stopPropagation();
                              onCancelSnooze();
                            }} // Use onCancelSnooze
                          />
                          <Button
                            title='Snooze'
                            modelbtn={true}
                            width='93'
                            className='button'
                            onClick={(e: SyntheticEvent) => {
                              e.stopPropagation();
                              onClickSnooze();
                            }}
                          />
                        </Buttonsdiv>
                      </Bottomdiv>
                    </Calendardropdown>
                  )}
                </Dropdown>

                <DropdownItem
                  label='Delete'
                  iconName='bin-icon'
                  onClick={(e) => onClickDeleteIcon(e, item)}
                  iconTone='stroke'
                />
              </Dropdown>
            </ResponsiveDropdown>
          )}
        </MSG>
      );
    });
  }, [
    filteredNotifications,
    openDropdownId,
    clearLongPress,
    getSvgIcon,
    renderProfileIcons,
    renderInboxButton,
    mobile,
    isCalendarOpen,
    snoozeMenuItems,
    selectedDate,
    selectedTime,
    hasMoved,
    onClickInboxItem,
    handlePressStart,
    handleMove,
    onClickReadIcon,
    handleClick,
    handleTimeChange,
    onCancelSnooze,
    onClickSnooze,
    onClickDeleteIcon
  ]);

  // Render desktop app notification card
  const renderDesktopNotificationCard = useMemo(() => {
    return (
      <NotificationCard>
        <MSGdetail className='desktopnotificarion-card'>
          <Avtardot>
            <DesktopIcon>
              <SVGIcon name='desktop-icon' width='16' height='16' viewBox='0 0 18 18' className='desktop-icon' />
            </DesktopIcon>
          </Avtardot>
          <RightContent>
            <Description>
              <MSGtitle className='desktopnotificarion-card'>Install Teamcamp to Your Desktop!</MSGtitle>
              <MSGtext className='desktopnotificarion-card'>
                Install the Teamcamp desktop app now and take your work to the next level with a faster,
                distraction-free experience.
              </MSGtext>
              <Bottombutton>
                <Button
                  title={'Get desktop app'}
                  secondary={true}
                  smallbutton={true}
                  hasNotBoxshadow
                  onClick={() => window.open('https://www.teamcamp.app/download', '_blank')}
                />
                <p onClick={onDismissDesktopNotification}>No, Thanks</p>
              </Bottombutton>
            </Description>
            <DesktopImage>
              {/* image for mac */}
              <img src={desktopappmac} alt='img' width={158} height={100} className='image' />
              {/* image for window */}
              {/* <img src={desktopappwindow} alt='img' width={158} height={100} className='image' /> */}
            </DesktopImage>
          </RightContent>
        </MSGdetail>
      </NotificationCard>
    );
  }, [onDismissDesktopNotification]);

  const renderInboxButtonCheck = useCallback(() => {
    if (selectedTask && typeof selectedTaskIndex === 'number') {
      return renderInboxButton(selectedTask, selectedTaskIndex, true);
    }
  }, [renderInboxButton, selectedTask, selectedTaskIndex]);

  return (
    <>
      <AppLayout isInbox={true} Inbox={true} isTaskDetailPreivewOpen={isTaskDetailOpen}>
        <Mainheader isScrollPage={isScrollPage}>
          <HeaderInbox>
            <Leftside>
              <Heading>
                <SVGIcon
                  name='inbox-header-icon'
                  width='24'
                  height='19'
                  viewBox='0 0 24 19'
                  fill='none'
                  className='inbox-header-icon'
                />
                <Title>Inbox</Title>
                {/* archive all and filter dropdown in mobile view */}
                <ResponsiveDropdown>
                  <Dropdown
                    content={
                      <ResponsiveArchiveIcon>
                        <SVGIcon
                          className='large-more-icon'
                          name='task-detail-dot-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                        />
                      </ResponsiveArchiveIcon>
                    }
                    trigger='click'>
                    <DropdownItem label='Archive all' onClick={() => OpenDeleteModel()} />
                  </Dropdown>
                  <Dropdown
                    content={
                      <FilterIcon>
                        <SVGIcon name='filter-icon' width='20' height='20' viewBox='0 0 20 20' fill='none' />
                      </FilterIcon>
                    }
                    trigger='click'
                    modalTitle='Filters'>
                    <DropdownItem
                      label='All Notifications'
                      onClick={() => inboxTabChange(INBOX_FILTER_TYPE.ALL)}
                      isSelected={filterType === INBOX_FILTER_TYPE.ALL || !filterType}
                    />
                    <DropdownItem
                      label='Assigned to me'
                      onClick={() => inboxTabChange(INBOX_FILTER_TYPE.ASSIGN_TO_ME)}
                      isSelected={filterType === INBOX_FILTER_TYPE.ASSIGN_TO_ME}
                    />
                    <DropdownItem
                      label='@mentioned'
                      onClick={() => inboxTabChange(INBOX_FILTER_TYPE.MENTION)}
                      isSelected={filterType === INBOX_FILTER_TYPE.MENTION}
                    />
                  </Dropdown>
                </ResponsiveDropdown>
              </Heading>
            </Leftside>
            {(loading || notifications?.length > 0) && (
              <>
                <Div>
                  <Menu>
                    <Buttons
                      isActive={filterType === INBOX_FILTER_TYPE.ALL || !filterType}
                      onClick={() => inboxTabChange(INBOX_FILTER_TYPE.ALL)}>
                      All
                    </Buttons>
                    <Buttons
                      isActive={filterType === INBOX_FILTER_TYPE.ASSIGN_TO_ME}
                      onClick={() => inboxTabChange(INBOX_FILTER_TYPE.ASSIGN_TO_ME)}>
                      Assigned to me
                    </Buttons>

                    <Buttons
                      isActive={filterType === INBOX_FILTER_TYPE.MENTION}
                      onClick={() => inboxTabChange(INBOX_FILTER_TYPE.MENTION)}>
                      @Mentioned
                    </Buttons>
                  </Menu>
                  <Dropdown
                    content={
                      <Rightside>
                        <SVGIcon
                          className='large-more-icon'
                          name='task-detail-dot-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                        />
                      </Rightside>
                    }
                    trigger='click'>
                    <DropdownItem label='Archive all' onClick={() => OpenDeleteModel()} />
                  </Dropdown>
                </Div>
              </>
            )}
          </HeaderInbox>
        </Mainheader>

        {/* Loading state */}
        {loading && filteredNotifications?.length === 0 && <Inboxloading />}

        {/* Emptystate */}
        {!loading &&
          filteredNotifications?.length === 0 &&
          !isShowOverdueCard &&
          !isShowDesktopNotification &&
          !(isShowNotificationCard && getCurrentDomain() === 'dash.teamcamp.app') && (
            <Empty>
              <EmptyState
                header='Your inbox is empty for now'
                // eslint-disable-next-line max-len
                title={`This is where you'll receive notifications for all types of activity. Enjoy your clutter-free inbox!`}
                hideButton={true}
                image={getImageSource()}
                ismaxwidth={true}
              />
            </Empty>
          )}
        {(filteredNotifications?.length > 0 ||
          isShowOverdueCard ||
          isShowNotificationCard ||
          isShowDesktopNotification) && (
          <InboxMSG>
            {/* Desktop app notification card */}
            {isShowDesktopNotification &&
              (!loading || filteredNotifications?.length > 0) &&
              renderDesktopNotificationCard}

            {isShowOverdueCard &&
              (!loading || filteredNotifications?.length > 0) &&
              renderCard({
                iconName: 'overdue-icon',
                title: `You have ${overDuetasks} overdue task!`,
                text: `It's good to be on schedule. Try to finish your overdue task or reschedule it.`,
                buttonTitle: 'View My Tasks',
                buttonAction: () => history.push(ROUTES.MYTASK),
                dismissAction: onClickDismiss,
                className: 'overdue-card'
              })}

            {isShowNotificationCard &&
              (!loading || filteredNotifications?.length > 0) &&
              renderCard({
                iconName: 'notification-inbox',
                title: 'Enable notifications and Stay in the Loop!',
                // eslint-disable-next-line max-len
                text: 'Enable notifications to receive real-time updates on task progress, new assignments, and important messages',
                buttonTitle: 'Enable Notifications',
                buttonAction: onEnableNotification,
                dismissAction: onClickNoEnableNotification,
                className: 'notification-card'
              })}

            {renderNotificationList}
          </InboxMSG>
        )}
        <ModalCustom open={deleteModelOpen} onClose={closeDeleteModel} width={337} isZindex={true}>
          <Deletemodal
            onClose={closeDeleteModel}
            loading={buttonLoading}
            isArchive={true}
            onOk={clearAllMessages}
            modaltitle='Archive all notifications?'
            description='Are you sure you want to archive all notifications?'
          />
        </ModalCustom>
      </AppLayout>
      {/* bottom navigation bar */}
      <BottomNavigation />

      {!isEmpty(selectedTask?.taskId) && !isEmpty(selectedTask?.projectId) && (
        <>
          {isTaskDetailOpen && (
            <>
              <Overlay onClick={handleCloseTaskDetail}></Overlay>
              <div>
                <Content>
                  <PreviewSection>
                    <DetailPreview>
                      <TaskDetailPreview
                        taskId={selectedTask?.taskId || ''}
                        projectId={selectedTask?.projectId || ''}
                        handleCloseTaskDetail={handleCloseTaskDetail}
                      />
                    </DetailPreview>
                    <ActionSection>
                      <InboxPreviewAction>
                        <ActionIcon
                          onClick={() => handleArrowClick('Backward')}
                          isDisabled={taskDetails?.id !== selectedTask?.taskId || buttonDisable.backward}>
                          <SVGIcon
                            name='left-arrow-icon'
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            className='upper-icon'
                          />
                        </ActionIcon>
                        <ActionIcon
                          onClick={() => handleArrowClick('Forward')}
                          isDisabled={taskDetails?.id !== selectedTask?.taskId || buttonDisable.forward}>
                          <SVGIcon
                            name='left-arrow-icon'
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            className='down-icon'
                          />
                        </ActionIcon>
                      </InboxPreviewAction>
                      {renderInboxButtonCheck()}
                    </ActionSection>
                  </PreviewSection>
                </Content>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default observer(Inbox);
