import styled, { css } from 'styled-components';

const NavbarMain = styled.div`
  width: 210px;
  position: relative;
  overflow: auto;
  display: flex;
  height: 100vh;
  box-orient: vertical;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-primary);
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    width: 100vw;
    margin: 0 -16px;
    background-color: var(--background-secondary);
  }
`;

const EmptyDiv = styled.div<{ isNavbarHide?: boolean }>`
  margin-right: 24px;
  ${(props: { isNavbarHide?: boolean }) =>
    props.isNavbarHide &&
    css`
      margin-right: 6px;
    `}
`;

const ActionIcon = styled.div<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  svg {
    path {
      stroke: var(--text-secondary);
    }
  }
  .left-icon {
    transform: rotate(0deg);
  }
  .right-icon {
    transform: rotate(180deg);
  }
  :hover {
    background: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    svg {
      path {
        stroke: var(--text-primary);
      }
    }
  }
  ${(props: { disabled?: boolean }) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;
export { NavbarMain, EmptyDiv, ActionIcon };
