import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  isInbox?: boolean;
  isMessageBox?: boolean;
  isTaskDetailPreivewOpen?: boolean;
}
interface Props {
  Inbox?: boolean;
  isPosition?: boolean;
  isPadding?: boolean;
  isNewDocPage?: boolean;
  isOverflowHidden?: boolean;
}
const MainSection = styled.div<Props>`
  display: flex;
  height: 100vh;
  .mobileview {
    display: none;
  }
  ${(props: Props) =>
    props.isTaskDetailPreivewOpen &&
    css`
      filter: blur(50px);
    `}
  @media (max-width: 449px) {
    flex-direction: column;
    height: 100%;
    ${(props: Props) =>
      props.isMessageBox &&
      css`
        height: 100dvh;
      `}
    .webview {
      display: none;
    }
    .mobileview {
      display: block;
      position: sticky;
      top: 0px;
      z-index: 99;
      width: 100%;
    }
  }
`;
const Content = styled.div<Props>`
  background: var(--background-secondary);
  width: 100%;
  overflow: auto;
  border-left: 0.5px solid var(--border-primary);
  padding: 0 24px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isPosition &&
    css`
      position: relative;
    `}
  ${(props: Props) =>
    props.isPadding &&
    css`
      padding-left: 0;
    `}
      ${(props: Props) =>
    props.isNewDocPage &&
    css`
      padding: 0;
    `}
    ${(props: Props) =>
    props.isOverflowHidden &&
    css`
      overflow: hidden;
    `}
  @media (max-width: 449px) {
    margin: 0;
    width: auto;
    border-left: 0;
    z-index: 0;
    height: 100dvh;
    padding: 0 16px;

    ${(props: Props) =>
      props.Inbox &&
      css`
        height: 100vh;
        padding: 0 12px;
      `}
  }
`;

const CMDKOverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-overlay);
  z-index: 99999;
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .more-icon {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
  .svgicon {
    stroke: var(--text-primary);
  }
  .large-more-icon {
    fill: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

export { MainSection, Content, CMDKOverLayDiv, Headline, Modalhead, Icon };
